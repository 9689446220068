import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ToomarContainer from "../containers/ToomarContainer";
import RadioButtonWithOrangeTick from "../kit/RadioButtonWithOrangeTick";
import ToomarInput from "../kit/ToomarInput";
import "./Cart.scss";
import CartItem from "./CartItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  setBuyingProduct,
  setFinishedProductDetails,
  setProductObjects,
  submitBuyProducts,
} from "../../redux/actions";
import { useLocation } from "react-router-dom";
import { priceFormat } from "../Utils/PriceFormat";
import OrangeTick from "../../assets/orangeTick.svg";
import { countries, countriesPhoneCodes, sendPrice } from "../Utils/Countries";

function Cart({ ...props }) {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("Iran, Islamic Republic Of");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [acceptLow, setAcceptLow] = useState(false);
  const [allPrice, setAllPrice] = useState(0);
  const [allDiscounted, setAllDiscounted] = useState(0);
  const [shippingCount, setShippingCount] = useState(0);
  const [isTehran, setIsTehran] = useState(false);
  const [sendMethod, setSendMethod] = useState(0);
  const [allIsDigital, setAllIsDigital] = useState(true);
  const [communicationWay, setCommunicationWay] = useState(2);
  const [koobPrice, setKoobPrice] = useState(0);
  const [countryCode, setCountryCode] = useState("98");
  const search = useLocation().search;
  const queryStep = new URLSearchParams(search).get("success");

  useEffect(() => {
    // if (isTehran == true && allPrice - allDiscounted + koobPrice > 99999999) {
    //   setShippingCount(0);
    // } else if (
    //   isTehran == false &&
    //   allPrice - allDiscounted + koobPrice > 99999999
    // ) {
    //   setShippingCount(0);
    // }
    for (let i = 0; i < props.buyingProducts.length; i++) {
      if (props.buyingProducts[i].data.is_digital != true) {
        setAllIsDigital(false);
      }
    }
    handleKoobPrice();
  }, [allPrice]);
  const handleKoobPrice = () => {
    var locKoobPrice = 0;
    for (let i = 0; i < props.finishedProductDetails.length; i++) {
      if (
        props.finishedProductDetails[i].koob == 5 ||
        props.finishedProductDetails[i].koob == 6
      ) {
        setKoobPrice(
          locKoobPrice +
            Math.ceil(parseInt(props.finishedProductDetails[i].count) / 100) *
              99
        );
        locKoobPrice =
          locKoobPrice +
          Math.ceil(parseInt(props.finishedProductDetails[i].count) / 100) * 99;
      }
    }
  };
  // useEffect(() => {
  //     // props.setFinishedProductDetails([]);
  //     if(queryStep == 'yes') {
  //         toast.success('پرداخت شما با موفقیت انجام شد')
  //         props.setBuyingProduct([]);
  //         props.setFinishedProductDetails([]);
  //     }
  // }, {})
  const handlePay = () => {
    let condition = props.buyingProducts.every((item) =>
      props.finishedProductDetails.some(
        (value) => value.product == item.data.id
      )
    );
    if (condition) {
      if (
        allIsDigital == false &&
        name != "" &&
        phoneNumber != "" &&
        province != "" &&
        city != "" &&
        address != ""
      ) {
        if (acceptLow == false) {
          toast.error("Accept laws is necessary");
        } else {
          let data = {
            name: name,
            phone_number: phoneNumber,
            address: address,
            country: country,
            province: province,
            city: city,
            redirect: `https://wedding.toomar.co/followup-last-order`,
            description: description,
            communicationWay: communicationWay === 1 ? "telegram" : "whatsapp",
            orders: [...props.finishedProductDetails],
            transform_price: parseInt(shippingCount),
            domain: "international",
          };
          props.submitBuyProducts(
            data,
            (value) => {
              const newWindow = window.open("", "_blank");
              newWindow.document.write(value.data);
              newWindow.document.close();
            },
            () => toast.error("Something went wrong")
          );
        }
      } else if (allIsDigital == true && name != "" && phoneNumber != "") {
        if (acceptLow == false) {
          toast.error("Accept laws is necessary");
        } else {
          let data = {
            name: name,
            phone_number: phoneNumber,
            address: address,
            redirect: `https://wedding.toomar.co/followup-last-order`,
            communicationWay: communicationWay === 1 ? "telegram" : "whatsapp",
            orders: [...props.finishedProductDetails],
            transform_price: parseInt(shippingCount),
            domain: "international",
          };
          props.submitBuyProducts(
            data,
            (value) => {
              // window.location.assign(value.data)
              const newWindow = window.open("", "_blank");
              newWindow.document.write(value.data);
              newWindow.document.close();
            },
            () => toast.error("Something went wrong")
          );
        }
      } else {
        toast.error("Please fill all customer information fields");
      }
    } else {
      toast.error("Please complete all products information");
    }
  };

  const handleSendMethod = (value) => {
    if (value == 1) {
      setSendMethod(value);
      if (allPrice - allDiscounted + koobPrice > 99999999) {
        setShippingCount(0);
      } else setShippingCount(100);
    } else if (value == 0) {
      setSendMethod(value);
      if (allPrice - allDiscounted + koobPrice > 99999999) {
        setShippingCount(0);
      } else setShippingCount(100);
    } else if (value == 2) {
      setSendMethod(value);
      if (allPrice - allDiscounted + koobPrice > 99999999) {
        setShippingCount(0);
      } else setShippingCount(100);
    }
  };
  const handleDeleteProduct = (data, index) => {
    let locProducts = [];
    let locFinishedProducts = [];
    let locProductOptions = [];
    locProducts = [...props.buyingProducts];
    locFinishedProducts = [...props.finishedProductDetails];
    if (props.productObj) {
      locProductOptions = [...props.productObj];
    }
    for (let i = 0; i < props.finishedProductDetails.length; i++) {
      if (props.finishedProductDetails[i].product == data.data.id) {
        locFinishedProducts.splice(i, 1);
        props.setFinishedProductDetails([...locFinishedProducts]);
        if (
          props.finishedProductDetails[i].koob == 5 ||
          props.finishedProductDetails[i].koob == 6
        ) {
          setKoobPrice(
            koobPrice -
              Math.ceil(parseInt(props.finishedProductDetails[i].count) / 100) *
                99
          );
        }
      }
    }
    for (let i = 0; i < props.productObj?.length; i++) {
      if (props.productObj[i]?.id == data.data.id) {
        locProductOptions.splice(i, 1);
        props.setProductObjects([...locFinishedProducts]);
      }
    }
    locProducts.splice(index, 1);
    props.setBuyingProduct([...locProducts]);
    var localAllPrice = 0;
    var localAllDiscount = 0;
    for (let i = 0; i < locProducts.length; i++) {
      localAllPrice =
        localAllPrice +
        locProducts[i].data.price * locProducts[i].count +
        locProducts[i].extraPrice;
      localAllDiscount =
        localAllDiscount +
        (locProducts[i].data.discount / 100) *
          locProducts[i].data.price *
          locProducts[i].count;
    }
    setAllPrice(localAllPrice);
    setAllDiscounted(localAllDiscount);
    // if (isTehran && localAllPrice - localAllDiscount > 99999999) {
    //   setShippingCount(0);
    // } else if (isTehran && localAllPrice - localAllDiscount > 99999999) {
    //   setShippingCount(0);
    // } else if (isTehran && localAllPrice - localAllDiscount <= 99999999) {
    //   setShippingCount(100);
    // } else if (sendMethod == 1) {
    //   setShippingCount(100);
    // } else if (sendMethod == 0) {
    //   setShippingCount(100);
    // } else if (sendMethod == 2) {
    //   setShippingCount(100);
    // }
  };

  useEffect(() => {
    if (country === "") {
      setCountryCode("98");
    } else {
      let code = countriesPhoneCodes.find(
        (item) =>
          country.includes(item.country) ||
          item.country === country ||
          item.country.includes(country)
      ).code;
      if (code) setCountryCode(code);
    }
  }, [country]);

  useEffect(() => {
    let totalWeights = 0;
    for (let i = 0; i < props.buyingProducts.length; i++) {
      if (props.buyingProducts[i].data.is_card) {
        totalWeights =
          totalWeights +
          parseFloat(props.buyingProducts[i].data.weight ?? 0) *
            parseInt(props.buyingProducts[i].count);
      }
    }
    let countryZone = countries.find((item) => item.name === country).zone;
    let shippingCountryZone = sendPrice[countryZone - 1];
    if (totalWeights <= 500)
      setShippingCount(shippingCountryZone[0] * Math.pow(10, 5));
    else if (totalWeights > 500 && totalWeights <= 1000)
      setShippingCount(shippingCountryZone[1] * Math.pow(10, 5));
    else if (totalWeights > 1000 && totalWeights <= 1500)
      setShippingCount(shippingCountryZone[2] * Math.pow(10, 5));
    else if (totalWeights > 1500 && totalWeights <= 2000)
      setShippingCount(shippingCountryZone[3] * Math.pow(10, 5));
    else if (totalWeights > 2000 && totalWeights <= 2500)
      setShippingCount(shippingCountryZone[4] * Math.pow(10, 5));
    else if (totalWeights > 2500 && totalWeights <= 3000)
      setShippingCount(shippingCountryZone[5] * Math.pow(10, 5));
    else if (totalWeights > 3000 && totalWeights <= 3500)
      setShippingCount(shippingCountryZone[6] * Math.pow(10, 5));
    else if (totalWeights > 3500 && totalWeights <= 4000)
      setShippingCount(shippingCountryZone[7] * Math.pow(10, 5));
    else if (totalWeights > 4000 && totalWeights <= 4500)
      setShippingCount(shippingCountryZone[8] * Math.pow(10, 5));
    else if (totalWeights > 4500)
      setShippingCount(shippingCountryZone[9] * Math.pow(10, 5));
  }, [allPrice, country]);

  const handleIsTehran = () => {
    setIsTehran(!isTehran);
    if (!isTehran == true && allPrice - allDiscounted + koobPrice > 99999999) {
      setShippingCount(0);
    } else if (
      !isTehran == false &&
      allPrice - allDiscounted + koobPrice > 99999999
    ) {
      setShippingCount(0);
    } else if (
      !isTehran == true &&
      allPrice - allDiscounted + koobPrice <= 99999999
    ) {
      setShippingCount(100);
    } else if (sendMethod == 1) {
      setShippingCount(100);
    } else if (sendMethod == 0) {
      setShippingCount(100);
    } else if (sendMethod == 2) {
      setShippingCount(100);
    }
  };

  return (
    <ToomarContainer>
      <ToastContainer />
      <div className={"cartContainer"}>
        <p>Cart</p>
        {props.buyingProducts.map((item, index) => (
          <CartItem
            key={item.data.id}
            data={item}
            index={index}
            allPrice={allPrice}
            allDiscounted={allDiscounted}
            setAllPrice={(value) => setAllPrice(value)}
            setAllDiscounted={(value) => setAllDiscounted(value)}
            setShippingCount={(value) => setShippingCount(value)}
            handleDeleteProduct={(data, index) =>
              handleDeleteProduct(data, index)
            }
            handleKoobPrice={handleKoobPrice}
          />
        ))}
        <div>
          <div>
            <p>Customer information</p>
            <div>
              <ToomarInput
                title={"Full name"}
                disabled={false}
                inputValue={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="countryNameContainer">
                <p>Country name</p>
                <select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countries.map((item) => (
                    <option value={item.name} key={item.name}>
                      {item.name}
                    </option>
                  ))}
                  {/* <option value={0}>
                                    ایتا
                                </option>
                                <option value={3}>
                                    سروش
                                </option> */}
                </select>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <p
                  style={{
                    fontFamily: "Aviny",
                    fontSize: "24px",
                    lineHeight: "38px",
                    margin: "0px 0px 6px",
                  }}
                >
                  Phone number
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid #e4e4e4",
                    padding: "15px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      fontFamily: "sans-serif",
                      margin: "0px 8px 0px",
                      padding: "0px 8px 0px 0px",
                      borderRight: "1px solid #e4e4e4",
                    }}
                  >
                    + {countryCode}
                  </p>
                  <input
                    className="noOutline"
                    type="number"
                    style={{ border: "none", width: "80%" }}
                    value={phoneNumber}
                    onChange={(e) =>
                      e.target.value.length <= 11
                        ? setPhoneNumber(e.target.value)
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            <div>
              <ToomarInput
                title={"State name"}
                disabled={allIsDigital == true ? true : false}
                inputValue={province}
                onChange={(e) => setProvince(e.target.value)}
              />
              <ToomarInput
                title={"City name"}
                disabled={allIsDigital == true ? true : false}
                inputValue={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div>
              <ToomarInput
                title={"Address"}
                disabled={allIsDigital == true ? true : false}
                inputValue={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div>
              <ToomarInput
                title={"Description"}
                // disabled={allIsDigital == true ? true : false}
                inputValue={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            {/* <RadioButtonWithOrangeTick
                        title={`تحویل در شهر تهران(مناطق ۲۰ گانه)`}
                        checked={allIsDigital == false ? isTehran : false}
                        onChange={handleIsTehran}
                        /> */}
            <div className="communicationWay">
              <p>Communication way</p>
              <select
                value={communicationWay}
                onChange={(e) => setCommunicationWay(e.target.value)}
              >
                <option value={1}>Telegram</option>
                <option value={2}>Whatsapp</option>
                {/* <option value={0}>
                                    ایتا
                                </option>
                                <option value={3}>
                                    سروش
                                </option> */}
              </select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <p>Shipping</p> */}
            </div>
            <p>
              {/* در نظر داشته باشید بسته های شهر تهران 2 الی 3 روز کاری و در شهرستان ها 3 تا 5 روز کاری زمان بر است. */}
            </p>
            <div
              className={"radioButtonContainer"}
              onClick={() => setAcceptLow(!acceptLow)}
            >
              <button>
                {acceptLow ? <img src={OrangeTick} alt={"checking"} /> : null}
              </button>
              <p>
                I have read and accept the{" "}
                <span
                  onClick={() => window.open("/laws/")}
                  style={{ color: "#916F40", cursor: "pointer" }}
                >
                  rules and regulations
                </span>{" "}
                of the site.
              </p>
            </div>
          </div>
          <div>
            <p>Price</p>
            <div>
              <p>Price</p>
              <p style={{ fontFamily: "sans-serif" }}>
                {`${priceFormat(allPrice)} $`}
              </p>
            </div>
            <div>
              <p>The cost of embossed printing</p>
              <p style={{ fontFamily: "sans-serif" }}>
                {`${priceFormat(koobPrice)} $`}
              </p>
            </div>
            <div>
              <p>Shipping price</p>
              <p style={{ fontFamily: "sans-serif" }}>
                {`${priceFormat(parseInt(shippingCount))} $`}
              </p>
            </div>
            <div>
              <p>Discount</p>
              <p style={{ fontFamily: "sans-serif" }}>
                {`${priceFormat(allDiscounted)} $`}
              </p>
            </div>
            {/* <div>
                            <p>
                                شماره سفارش 
                            </p>
                            <p>
                                ۱۲۴۹
                            </p>
                        </div> */}
            <div>
              <p>Full price</p>
              <p style={{ fontFamily: "sans-serif" }}>
                {allIsDigital == true
                  ? `${priceFormat(allPrice - allDiscounted + koobPrice)} $`
                  : `${priceFormat(
                      allPrice - allDiscounted + shippingCount + koobPrice
                    )} $`}
              </p>
            </div>
            <p>
              <span
                onClick={() => window.open("/contact-us/")}
                style={{ color: "#916F40", cursor: "pointer" }}
              >
                Contact
              </span>{" "}
              us if you need advice or guidance
            </p>
            <button onClick={handlePay}>Pay</button>
          </div>
        </div>
      </div>
    </ToomarContainer>
  );
}

const mapStateToProps = (state) => ({
  buyingProducts: state.counter.buyingProducts,
  finishedProductDetails: state.counter.finishedProductDetails,
  productObj: state.counter.productObj,
});

export default connect(mapStateToProps, {
  submitBuyProducts,
  setBuyingProduct,
  setFinishedProductDetails,
  setProductObjects,
})(Cart);
