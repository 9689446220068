const dollar = 60000;

export const countries = [
  { name: "Afghanistan", code: "AF", zone: 7 },
  { name: "Albania", code: "AL", zone: 5 },
  { name: "Algeria", code: "DZ", zone: 6 },
  { name: "American Samoa", code: "AS", zone: 7 },
  { name: "AndorrA", code: "AD", zone: 7 },
  { name: "Angola", code: "AO", zone: 7 },
  { name: "Anguilla", code: "AI", zone: 7 },
  { name: "Antigua and Barbuda", code: "AG", zone: 7 },
  { name: "Argentina", code: "AR", zone: 6 },
  { name: "Armenia", code: "AM", zone: 6 },
  { name: "Aruba", code: "AW", zone: 7 },
  { name: "Australia", code: "AU", zone: 4 },
  { name: "Austria", code: "AT", zone: 3 },
  { name: "Azerbaijan", code: "AZ", zone: 6 },
  { name: "Bahamas", code: "BS", zone: 7 },
  { name: "Bahrain", code: "BH", zone: 1 },
  { name: "Bangladesh", code: "BD", zone: 2 },
  { name: "Barbados", code: "BB", zone: 7 },
  { name: "Belarus", code: "BY", zone: 6 },
  { name: "Belgium", code: "BE", zone: 2 },
  { name: "Belize", code: "BZ", zone: 7 },
  { name: "Benin", code: "BJ", zone: 7 },
  { name: "Bermuda", code: "BM", zone: 7 },
  { name: "Bhutan", code: "BT", zone: 7 },
  { name: "Bolivia", code: "BO", zone: 6 },
  { name: "Bonaire", code: "XB", zone: 7 },
  { name: "Bosnia and Herzegovina", code: "BA", zone: 6 },
  { name: "Botswana", code: "BW", zone: 7 },
  { name: "Brazil", code: "BR", zone: 6 },
  { name: "Brunei Darussalam", code: "BN", zone: 7 },
  { name: "Bulgaria", code: "BG", zone: 6 },
  { name: "Burkina Faso", code: "BF", zone: 7 },
  { name: "Burundi", code: "BI", zone: 7 },
  { name: "Cambodia", code: "KH", zone: 4 },
  { name: "Cameroon", code: "CM", zone: 7 },
  { name: "Canada", code: "CA", zone: 2 },
  { name: "Canary Island, The", code: "IC", zone: 6 },
  { name: "Cape Verde", code: "CV", zone: 7 },
  { name: "Cayman Islands", code: "KY", zone: 6 },
  { name: "Central African Republic", code: "CF", zone: 7 },
  { name: "Chad", code: "TD", zone: 7 },
  { name: "Chile", code: "CL", zone: 6 },
  { name: "China", code: "CN", zone: 5 },
  //   { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO", zone: 6 },
  { name: "Comoros", code: "KM", zone: 7 },
  { name: "Congo", code: "CG", zone: 7 },
  { name: "Congo, The Democratic Republic of the", code: "CD", zone: 6 },
  { name: "Cook Islands", code: "CK", zone: 7 },
  { name: "Costa Rica", code: "CR", zone: 7 },
  { name: "Cote D'Ivoire", code: "CI", zone: 6 },
  { name: "Croatia", code: "HR", zone: 6 },
  { name: "Cuba", code: "CU", zone: 6 },
  { name: "Curacao", code: "XD", zone: 7 },
  { name: "Cyprus", code: "CY", zone: 5 },
  { name: "Czech Republic", code: "CZ", zone: 4 },
  { name: "Denmark", code: "DK", zone: 2 },
  { name: "Djibouti", code: "DJ", zone: 7 },
  { name: "Dominica", code: "DM", zone: 7 },
  { name: "Dominican Republic", code: "DO", zone: 6 },
  { name: "Ecuador", code: "EC", zone: 6 },
  { name: "Egypt", code: "EG", zone: 2 },
  { name: "El Salvador", code: "SV", zone: 7 },
  //   { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER", zone: 7 },
  { name: "Estonia", code: "EE", zone: 6 },
  { name: "Ethiopia", code: "ET", zone: 6 },
  { name: "Falkland Islands (Malvinas)", code: "FK", zone: 7 },
  { name: "Faroe Islands", code: "FO", zone: 7 },
  { name: "Fiji", code: "FJ", zone: 7 },
  { name: "Finland", code: "FI", zone: 2 },
  { name: "France", code: "FR", zone: 2 },
  { name: "French Guiana", code: "GF", zone: 7 },
  { name: "French Polynesia", code: "PF", zone: 7 },
  //   { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA", zone: 7 },
  { name: "Gambia", code: "GM", zone: 7 },
  { name: "Georgia", code: "GE", zone: 5 },
  { name: "Germany", code: "DE", zone: 2 },
  { name: "Ghana", code: "GH", zone: 6 },
  { name: "Gibraltar", code: "GI", zone: 7 },
  { name: "Greece", code: "GR", zone: 2 },
  { name: "Greenland", code: "GL", zone: 7 },
  { name: "Grenada", code: "GD", zone: 7 },
  { name: "Guadeloupe", code: "GP", zone: 7 },
  { name: "Guam", code: "GU", zone: 7 },
  { name: "Guatemala", code: "GT", zone: 7 },
  { name: "Guernsey", code: "GG", zone: 7 },
  { name: "Guinea", code: "GN", zone: 6 },
  { name: "Guinea-Bissau", code: "GW", zone: 7 },
  { name: "Guyana", code: "GY", zone: 7 },
  { name: "Haiti", code: "HT", zone: 7 },
  //   { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA", zone: 7 },
  { name: "Honduras", code: "HN", zone: 7 },
  { name: "Hong Kong", code: "HK", zone: 4 },
  { name: "Hungary", code: "HU", zone: 6 },
  { name: "Iceland", code: "IS", zone: 6 },
  { name: "India", code: "IN", zone: 2 },
  { name: "Indonesia", code: "ID", zone: 5 },
  { name: "Iran, Islamic Republic Of", code: "IR", zone: 7 },
  { name: "Iraq", code: "IQ", zone: 7 },
  { name: "Ireland", code: "IE", zone: 3 },
  //   { name: "Isle of Man", code: "IM" },
  //   { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT", zone: 2 },
  { name: "Jamaica", code: "JM", zone: 7 },
  { name: "Japan", code: "JP", zone: 5 },
  { name: "Jersey", code: "JE", zone: 7 },
  { name: "Jordan", code: "JO", zone: 3 },
  { name: "Kazakhstan", code: "KZ", zone: 6 },
  { name: "Kenya", code: "KE", zone: 6 },
  { name: "Kiribati", code: "KI", zone: 7 },
  { name: "Korea, Democratic People's Republic of", code: "KP", zone: 7 },
  { name: "Korea, Republic of", code: "KR", zone: 5 },
  { name: "Kosovo", code: "KV", zone: 7 },
  { name: "Kuwait", code: "KW", zone: 1 },
  { name: "Kyrgyzstan", code: "KG", zone: 6 },
  { name: "Lao People's Democratic Republic", code: "LA", zone: 7 },
  { name: "Latvia", code: "LV", zone: 6 },
  { name: "Lebanon", code: "LB", zone: 3 },
  { name: "Lesotho", code: "LS", zone: 7 },
  { name: "Liberia", code: "LR", zone: 7 },
  { name: "Libyan Arab Jamahiriya", code: "LY", zone: 8 },
  { name: "Liechtenstein", code: "LI", zone: 6 },
  { name: "Lithuania", code: "LT", zone: 4 },
  { name: "Luxembourg", code: "LU", zone: 3 },
  { name: "Macao", code: "MO", zone: 7 },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK", zone: 7 },
  { name: "Madagascar", code: "MG", zone: 7 },
  { name: "Malawi", code: "MW", zone: 7 },
  { name: "Malaysia", code: "MY", zone: 4 },
  { name: "Maldives", code: "MV", zone: 5 },
  { name: "Mali", code: "ML", zone: 6 },
  { name: "Malta", code: "MT", zone: 5 },
  { name: "Marshall Islands", code: "MH", zone: 7 },
  { name: "Martinique", code: "MQ", zone: 7 },
  { name: "Mauritania", code: "MR", zone: 7 },
  { name: "Mauritius", code: "MU", zone: 7 },
  { name: "Mayotte", code: "YT", zone: 7 },
  { name: "Mexico", code: "MX", zone: 6 },
  { name: "Micronesia, Federated States of", code: "FM", zone: 7 },
  { name: "Moldova, Republic of", code: "MD", zone: 7 },
  { name: "Monaco", code: "MC", zone: 7 },
  { name: "Mongolia", code: "MN", zone: 6 },
  { name: "Montenegro", code: "ME", zone: 7 },
  { name: "Montserrat", code: "MS", zone: 7 },
  { name: "Morocco", code: "MA", zone: 5 },
  { name: "Mozambique", code: "MZ", zone: 7 },
  { name: "Myanmar", code: "MM", zone: 7 },
  { name: "Namibia", code: "NA", zone: 7 },
  { name: "Nauru", code: "NR", zone: 7 },
  { name: "Nepal", code: "NP", zone: 5 },
  { name: "Netherlands", code: "NL", zone: 2 },
  { name: "Nevis", code: "XN", zone: 7 },
  //   { name: "Netherlands Antilles", code: "AN", zone: 5 },
  { name: "New Caledonia", code: "NC", zone: 7 },
  { name: "New Zealand", code: "NZ", zone: 5 },
  { name: "Nicaragua", code: "NI", zone: 7 },
  { name: "Niger", code: "NE", zone: 7 },
  { name: "Nigeria", code: "NG", zone: 7 },
  { name: "Niue", code: "NU", zone: 7 },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP", zone: 7 },
  { name: "Norway", code: "NO", zone: 5 },
  { name: "Oman", code: "OM", zone: 1 },
  { name: "Pakistan", code: "PK", zone: 2 },
  { name: "Palau", code: "PW", zone: 7 },
  //   { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA", zone: 6 },
  { name: "Papua New Guinea", code: "PG", zone: 7 },
  { name: "Paraguay", code: "PY", zone: 5 },
  { name: "Peru", code: "PE", zone: 5 },
  { name: "Philippines", code: "PH", zone: 2 },
  //   { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL", zone: 4 },
  { name: "Portugal", code: "PT", zone: 2 },
  { name: "Puerto Rico", code: "PR", zone: 7 },
  { name: "Qatar", code: "QA", zone: 1 },
  { name: "Reunion", code: "RE", zone: 7 },
  { name: "Romania", code: "RO", zone: 4 },
  { name: "Russian Federation", code: "RU", zone: 7 },
  { name: "RWANDA", code: "RW", zone: 7 },
  { name: "Saint Helena", code: "SH", zone: 7 },
  //   { name: "Saint Kitts and Nevis", code: "KN" },
  //   { name: "Saint Lucia", code: "LC" },
  //   { name: "Saint Pierre and Miquelon", code: "PM" },
  //   { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS", zone: 7 },
  { name: "San Marino", code: "SM", zone: 7 },
  { name: "Sao Tome and Principe", code: "ST", zone: 7 },
  { name: "Saudi Arabia", code: "SA", zone: 2 },
  { name: "Senegal", code: "SN", zone: 7 },
  { name: "Serbia", code: "RS", zone: 6 },
  { name: "Seychelles", code: "SC", zone: 7 },
  { name: "Sierra Leone", code: "SL", zone: 6 },
  { name: "Singapore", code: "SG", zone: 3 },
  { name: "Slovakia", code: "SK", zone: 5 },
  { name: "Slovenia", code: "SI", zone: 7 },
  { name: "Solomon Islands", code: "SB", zone: 7 },
  { name: "Somalia", code: "SO", zone: 7 },
  { name: "Somaliland, Rep of", code: "XS", zone: 7 },
  { name: "South Africa", code: "ZA", zone: 5 },
  { name: "South Soudan", code: "SS", zone: 8 },
  //   { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES", zone: 2 },
  { name: "Sri Lanka", code: "LK", zone: 2 },
  { name: "St. Barthelemy", code: "XY", zone: 7 },
  { name: "St. Eustatius", code: "XE", zone: 7 },
  { name: "St. Kitts", code: "KN", zone: 7 },
  { name: "St. Lucia", code: "LC", zone: 7 },
  { name: "St. Maarten", code: "XM", zone: 7 },
  { name: "St. Vincent", code: "VC", zone: 7 },
  { name: "Sudan", code: "SD", zone: 8 },
  { name: "Suriname", code: "SR", zone: 7 },
  //   { name: "Svalbard and Jan Mayen", code: "SJ", zone: 7 },
  { name: "Swaziland", code: "SZ", zone: 7 },
  { name: "Sweden", code: "SE", zone: 2 },
  { name: "Switzerland", code: "CH", zone: 3 },
  { name: "Syrian Arab Republic", code: "SY", zone: 8 },
  { name: "Taiwan, Province of China", code: "TW", zone: 5 },
  { name: "Tajikistan", code: "TJ", zone: 6 },
  { name: "Tanzania, United Republic of", code: "TZ", zone: 5 },
  { name: "Thailand", code: "TH", zone: 5 },
  { name: "Timor-Leste", code: "TL", zone: 7 },
  { name: "Togo", code: "TG", zone: 7 },
  //   { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO", zone: 7 },
  { name: "Trinidad and Tobago", code: "TT", zone: 7 },
  { name: "Tunisia", code: "TN", zone: 6 },
  { name: "Turkey", code: "TR", zone: 5 },
  { name: "Turkmenistan", code: "TM", zone: 6 },
  { name: "Turks and Caicos Islands", code: "TC", zone: 7 },
  { name: "Tuvalu", code: "TV", zone: 7 },
  { name: "Uganda", code: "UG", zone: 7 },
  { name: "Ukraine", code: "UA", zone: 6 },
  { name: "United Arab Emirates", code: "AE", zone: 10 },
  { name: "United Kingdom", code: "GB", zone: 2 },
  { name: "United States", code: "US", zone: 3 },
  //   { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY", zone: 6 },
  { name: "Uzbekistan", code: "UZ", zone: 6 },
  { name: "Vanuatu", code: "VU", zone: 7 },
  { name: "Venezuela", code: "VE", zone: 5 },
  { name: "Viet Nam", code: "VN", zone: 5 },
  { name: "Virgin Islands, British", code: "VG", zone: 7 },
  { name: "Virgin Islands, U.S.", code: "VI", zone: 7 },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE", zone: 8 },
  { name: "Zambia", code: "ZM", zone: 7 },
  { name: "Zimbabwe", code: "AE", zone: 9 },
  { name: "Dubai", code: "ZW", zone: 7 },
];

export const sendPrice = [
  //zone 1
  [
    24 / dollar,
    29 / dollar,
    36 / dollar,
    41 / dollar,
    47 / dollar,
    52 / dollar,
    61 / dollar,
    66 / dollar,
    73 / dollar,
    77 / dollar,
  ],
  //zone 2
  [
    26 / dollar,
    29 / dollar,
    35 / dollar,
    41 / dollar,
    46 / dollar,
    52 / dollar,
    58 / dollar,
    63 / dollar,
    69 / dollar,
    773 / dollar,
  ],
  //zone 3
  [
    30 / dollar,
    34 / dollar,
    42 / dollar,
    48 / dollar,
    57 / dollar,
    63 / dollar,
    74 / dollar,
    80 / dollar,
    90 / dollar,
    96 / dollar,
  ],
  //zone 4
  [
    31 / dollar,
    36 / dollar,
    45 / dollar,
    51 / dollar,
    63 / dollar,
    68 / dollar,
    82 / dollar,
    90 / dollar,
    103 / dollar,
    111 / dollar,
  ],
  //zone 5
  [
    38 / dollar,
    40 / dollar,
    47 / dollar,
    50 / dollar,
    59 / dollar,
    62 / dollar,
    77 / dollar,
    86 / dollar,
    100 / dollar,
    101 / dollar,
  ],
  //zone 6
  [
    36 / dollar,
    48 / dollar,
    60 / dollar,
    71 / dollar,
    85 / dollar,
    96 / dollar,
    110 / dollar,
    125 / dollar,
    144 / dollar,
    160 / dollar,
  ],
  //zone 7
  [
    36 / dollar,
    48 / dollar,
    60 / dollar,
    71 / dollar,
    85 / dollar,
    96 / dollar,
    110 / dollar,
    125 / dollar,
    144 / dollar,
    160 / dollar,
  ],
  //zone 8
  [
    36 / dollar,
    48 / dollar,
    60 / dollar,
    71 / dollar,
    85 / dollar,
    96 / dollar,
    110 / dollar,
    125 / dollar,
    144 / dollar,
    160 / dollar,
  ],
  //zone 9
  [
    36 / dollar,
    48 / dollar,
    60 / dollar,
    71 / dollar,
    85 / dollar,
    96 / dollar,
    110 / dollar,
    125 / dollar,
    144 / dollar,
    160 / dollar,
  ],
  //zone 10
  [
    10 / dollar,
    14 / dollar,
    16 / dollar,
    18 / dollar,
    22 / dollar,
    24 / dollar,
    29 / dollar,
    33 / dollar,
    40 / dollar,
    42 / dollar,
  ],
];

export const countriesPhoneCodes = [
  { country: "Afghanistan", code: "93", iso: "AF" },
  { country: "Albania", code: "355", iso: "AL" },
  { country: "Algeria", code: "213", iso: "DZ" },
  { country: "American Samoa", code: "1-684", iso: "AS" },
  { country: "Andorra", code: "376", iso: "AD" },
  { country: "Angola", code: "244", iso: "AO" },
  { country: "Anguilla", code: "1-264", iso: "AI" },
  { country: "Antarctica", code: "672", iso: "AQ" },
  { country: "Antigua and Barbuda", code: "1-268", iso: "AG" },
  { country: "Argentina", code: "54", iso: "AR" },
  { country: "Armenia", code: "374", iso: "AM" },
  { country: "Aruba", code: "297", iso: "AW" },
  { country: "Australia", code: "61", iso: "AU" },
  { country: "Austria", code: "43", iso: "AT" },
  { country: "Azerbaijan", code: "994", iso: "AZ" },
  { country: "Bahamas", code: "1-242", iso: "BS" },
  { country: "Bahrain", code: "973", iso: "BH" },
  { country: "Bangladesh", code: "880", iso: "BD" },
  { country: "Barbados", code: "1-246", iso: "BB" },
  { country: "Belarus", code: "375", iso: "BY" },
  { country: "Belgium", code: "32", iso: "BE" },
  { country: "Belize", code: "501", iso: "BZ" },
  { country: "Benin", code: "229", iso: "BJ" },
  { country: "Bermuda", code: "1-441", iso: "BM" },
  { country: "Bhutan", code: "975", iso: "BT" },
  { country: "Bolivia", code: "591", iso: "BO" },
  { country: "Bosnia and Herzegovina", code: "387", iso: "BA" },
  { country: "Botswana", code: "267", iso: "BW" },
  { country: "Brazil", code: "55", iso: "BR" },
  { country: "British Indian Ocean Territory", code: "246", iso: "IO" },
  { country: "British Virgin Islands", code: "1-284", iso: "VG" },
  { country: "Brunei", code: "673", iso: "BN" },
  { country: "Bulgaria", code: "359", iso: "BG" },
  { country: "Burkina Faso", code: "226", iso: "BF" },
  { country: "Burundi", code: "257", iso: "BI" },
  { country: "Cambodia", code: "855", iso: "KH" },
  { country: "Cameroon", code: "237", iso: "CM" },
  { country: "Canada", code: "1", iso: "CA" },
  { country: "Cape Verde", code: "238", iso: "CV" },
  { country: "Cayman Islands", code: "1-345", iso: "KY" },
  { country: "Central African Republic", code: "236", iso: "CF" },
  { country: "Chad", code: "235", iso: "TD" },
  { country: "Chile", code: "56", iso: "CL" },
  { country: "China", code: "86", iso: "CN" },
  { country: "Christmas Island", code: "61", iso: "CX" },
  { country: "Cocos Islands", code: "61", iso: "CC" },
  { country: "Colombia", code: "57", iso: "CO" },
  { country: "Comoros", code: "269", iso: "KM" },
  { country: "Cook Islands", code: "682", iso: "CK" },
  { country: "Costa Rica", code: "506", iso: "CR" },
  { country: "Croatia", code: "385", iso: "HR" },
  { country: "Cuba", code: "53", iso: "CU" },
  { country: "Curacao", code: "599", iso: "CW" },
  { country: "Cyprus", code: "357", iso: "CY" },
  { country: "Czech Republic", code: "420", iso: "CZ" },
  { country: "Democratic Republic of the Congo", code: "243", iso: "CD" },
  { country: "Denmark", code: "45", iso: "DK" },
  { country: "Djibouti", code: "253", iso: "DJ" },
  { country: "Dominica", code: "1-767", iso: "DM" },
  { country: "Dominican Republic", code: "1-809, 1-829, 1-849", iso: "DO" },
  { country: "East Timor", code: "670", iso: "TL" },
  { country: "Ecuador", code: "593", iso: "EC" },
  { country: "Egypt", code: "20", iso: "EG" },
  { country: "El Salvador", code: "503", iso: "SV" },
  { country: "Equatorial Guinea", code: "240", iso: "GQ" },
  { country: "Eritrea", code: "291", iso: "ER" },
  { country: "Estonia", code: "372", iso: "EE" },
  { country: "Ethiopia", code: "251", iso: "ET" },
  { country: "Falkland Islands", code: "500", iso: "FK" },
  { country: "Faroe Islands", code: "298", iso: "FO" },
  { country: "Fiji", code: "679", iso: "FJ" },
  { country: "Finland", code: "358", iso: "FI" },
  { country: "France", code: "33", iso: "FR" },
  { country: "French Polynesia", code: "689", iso: "PF" },
  { country: "Gabon", code: "241", iso: "GA" },
  { country: "Gambia", code: "220", iso: "GM" },
  { country: "Georgia", code: "995", iso: "GE" },
  { country: "Germany", code: "49", iso: "DE" },
  { country: "Ghana", code: "233", iso: "GH" },
  { country: "Gibraltar", code: "350", iso: "GI" },
  { country: "Greece", code: "30", iso: "GR" },
  { country: "Greenland", code: "299", iso: "GL" },
  { country: "Grenada", code: "1-473", iso: "GD" },
  { country: "Guam", code: "1-671", iso: "GU" },
  { country: "Guatemala", code: "502", iso: "GT" },
  { country: "Guernsey", code: "44-1481", iso: "GG" },
  { country: "Guinea", code: "224", iso: "GN" },
  { country: "Guinea-Bissau", code: "245", iso: "GW" },
  { country: "Guyana", code: "592", iso: "GY" },
  { country: "Haiti", code: "509", iso: "HT" },
  { country: "Honduras", code: "504", iso: "HN" },
  { country: "Hong Kong", code: "852", iso: "HK" },
  { country: "Hungary", code: "36", iso: "HU" },
  { country: "Iceland", code: "354", iso: "IS" },
  { country: "India", code: "91", iso: "IN" },
  { country: "Indonesia", code: "62", iso: "ID" },
  { country: "Iran", code: "98", iso: "IR" },
  { country: "Iraq", code: "964", iso: "IQ" },
  { country: "Ireland", code: "353", iso: "IE" },
  { country: "Isle of Man", code: "44-1624", iso: "IM" },
  { country: "Israel", code: "972", iso: "IL" },
  { country: "Italy", code: "39", iso: "IT" },
  { country: "Ivory Coast", code: "225", iso: "CI" },
  { country: "Jamaica", code: "1-876", iso: "JM" },
  { country: "Japan", code: "81", iso: "JP" },
  { country: "Jersey", code: "44-1534", iso: "JE" },
  { country: "Jordan", code: "962", iso: "JO" },
  { country: "Kazakhstan", code: "7", iso: "KZ" },
  { country: "Kenya", code: "254", iso: "KE" },
  { country: "Kiribati", code: "686", iso: "KI" },
  { country: "Kosovo", code: "383", iso: "XK" },
  { country: "Kuwait", code: "965", iso: "KW" },
  { country: "Kyrgyzstan", code: "996", iso: "KG" },
  { country: "Laos", code: "856", iso: "LA" },
  { country: "Latvia", code: "371", iso: "LV" },
  { country: "Lebanon", code: "961", iso: "LB" },
  { country: "Lesotho", code: "266", iso: "LS" },
  { country: "Liberia", code: "231", iso: "LR" },
  { country: "Libya", code: "218", iso: "LY" },
  { country: "Liechtenstein", code: "423", iso: "LI" },
  { country: "Lithuania", code: "370", iso: "LT" },
  { country: "Luxembourg", code: "352", iso: "LU" },
  { country: "Macao", code: "853", iso: "MO" },
  { country: "Macedonia", code: "389", iso: "MK" },
  { country: "Madagascar", code: "261", iso: "MG" },
  { country: "Malawi", code: "265", iso: "MW" },
  { country: "Malaysia", code: "60", iso: "MY" },
  { country: "Maldives", code: "960", iso: "MV" },
  { country: "Mali", code: "223", iso: "ML" },
  { country: "Malta", code: "356", iso: "MT" },
  { country: "Marshall Islands", code: "692", iso: "MH" },
  { country: "Mauritania", code: "222", iso: "MR" },
  { country: "Mauritius", code: "230", iso: "MU" },
  { country: "Mayotte", code: "262", iso: "YT" },
  { country: "Mexico", code: "52", iso: "MX" },
  { country: "Micronesia", code: "691", iso: "FM" },
  { country: "Moldova", code: "373", iso: "MD" },
  { country: "Monaco", code: "377", iso: "MC" },
  { country: "Mongolia", code: "976", iso: "MN" },
  { country: "Montenegro", code: "382", iso: "ME" },
  { country: "Montserrat", code: "1-664", iso: "MS" },
  { country: "Morocco", code: "212", iso: "MA" },
  { country: "Mozambique", code: "258", iso: "MZ" },
  { country: "Myanmar", code: "95", iso: "MM" },
  { country: "Namibia", code: "264", iso: "NA" },
  { country: "Nauru", code: "674", iso: "NR" },
  { country: "Nepal", code: "977", iso: "NP" },
  { country: "Netherlands", code: "31", iso: "NL" },
  { country: "Netherlands Antilles", code: "599", iso: "AN" },
  { country: "New Caledonia", code: "687", iso: "NC" },
  { country: "New Zealand", code: "64", iso: "NZ" },
  { country: "Nicaragua", code: "505", iso: "NI" },
  { country: "Niger", code: "227", iso: "NE" },
  { country: "Nigeria", code: "234", iso: "NG" },
  { country: "Niue", code: "683", iso: "NU" },
  { country: "North Korea", code: "850", iso: "KP" },
  { country: "Northern Mariana Islands", code: "1-670", iso: "MP" },
  { country: "Norway", code: "47", iso: "NO" },
  { country: "Oman", code: "968", iso: "OM" },
  { country: "Pakistan", code: "92", iso: "PK" },
  { country: "Palau", code: "680", iso: "PW" },
  { country: "Palestine", code: "970", iso: "PS" },
  { country: "Panama", code: "507", iso: "PA" },
  { country: "Papua New Guinea", code: "675", iso: "PG" },
  { country: "Paraguay", code: "595", iso: "PY" },
  { country: "Peru", code: "51", iso: "PE" },
  { country: "Philippines", code: "63", iso: "PH" },
  { country: "Pitcairn", code: "64", iso: "PN" },
  { country: "Poland", code: "48", iso: "PL" },
  { country: "Portugal", code: "351", iso: "PT" },
  { country: "Puerto Rico", code: "1-787, 1-939", iso: "PR" },
  { country: "Qatar", code: "974", iso: "QA" },
  { country: "Republic of the Congo", code: "242", iso: "CG" },
  { country: "Reunion", code: "262", iso: "RE" },
  { country: "Romania", code: "40", iso: "RO" },
  { country: "Russia", code: "7", iso: "RU" },
  { country: "Rwanda", code: "250", iso: "RW" },
  { country: "Saint Barthelemy", code: "590", iso: "BL" },
  { country: "Saint Helena", code: "290", iso: "SH" },
  { country: "Saint Kitts and Nevis", code: "1-869", iso: "KN" },
  { country: "Saint Lucia", code: "1-758", iso: "LC" },
  { country: "Saint Martin", code: "590", iso: "MF" },
  { country: "Saint Pierre and Miquelon", code: "508", iso: "PM" },
  { country: "Saint Vincent and the Grenadines", code: "1-784", iso: "VC" },
  { country: "Samoa", code: "685", iso: "WS" },
  { country: "San Marino", code: "378", iso: "SM" },
  { country: "Sao Tome and Principe", code: "239", iso: "ST" },
  { country: "Saudi Arabia", code: "966", iso: "SA" },
  { country: "Senegal", code: "221", iso: "SN" },
  { country: "Serbia", code: "381", iso: "RS" },
  { country: "Seychelles", code: "248", iso: "SC" },
  { country: "Sierra Leone", code: "232", iso: "SL" },
  { country: "Singapore", code: "65", iso: "SG" },
  { country: "Sint Maarten", code: "1-721", iso: "SX" },
  { country: "Slovakia", code: "421", iso: "SK" },
  { country: "Slovenia", code: "386", iso: "SI" },
  { country: "Solomon Islands", code: "677", iso: "SB" },
  { country: "Somalia", code: "252", iso: "SO" },
  { country: "South Africa", code: "27", iso: "ZA" },
  { country: "South Korea", code: "82", iso: "KR" },
  { country: "South Sudan", code: "211", iso: "SS" },
  { country: "Spain", code: "34", iso: "ES" },
  { country: "Sri Lanka", code: "94", iso: "LK" },
  { country: "Sudan", code: "249", iso: "SD" },
  { country: "Suriname", code: "597", iso: "SR" },
  { country: "Svalbard and Jan Mayen", code: "47", iso: "SJ" },
  { country: "Swaziland", code: "268", iso: "SZ" },
  { country: "Sweden", code: "46", iso: "SE" },
  { country: "Switzerland", code: "41", iso: "CH" },
  { country: "Syria", code: "963", iso: "SY" },
  { country: "Taiwan", code: "886", iso: "TW" },
  { country: "Tajikistan", code: "992", iso: "TJ" },
  { country: "Tanzania", code: "255", iso: "TZ" },
  { country: "Thailand", code: "66", iso: "TH" },
  { country: "Togo", code: "228", iso: "TG" },
  { country: "Tokelau", code: "690", iso: "TK" },
  { country: "Tonga", code: "676", iso: "TO" },
  { country: "Trinidad and Tobago", code: "1-868", iso: "TT" },
  { country: "Tunisia", code: "216", iso: "TN" },
  { country: "Turkey", code: "90", iso: "TR" },
  { country: "Turkmenistan", code: "993", iso: "TM" },
  { country: "Turks and Caicos Islands", code: "1-649", iso: "TC" },
  { country: "Tuvalu", code: "688", iso: "TV" },
  { country: "U.S. Virgin Islands", code: "1-340", iso: "VI" },
  { country: "Uganda", code: "256", iso: "UG" },
  { country: "Ukraine", code: "380", iso: "UA" },
  { country: "United Arab Emirates", code: "971", iso: "AE" },
  { country: "United Kingdom", code: "44", iso: "GB" },
  { country: "United States", code: "1", iso: "US" },
  { country: "Uruguay", code: "598", iso: "UY" },
  { country: "Uzbekistan", code: "998", iso: "UZ" },
  { country: "Vanuatu", code: "678", iso: "VU" },
  { country: "Vatican", code: "379", iso: "VA" },
  { country: "Venezuela", code: "58", iso: "VE" },
  { country: "Vietnam", code: "84", iso: "VN" },
  { country: "Wallis and Futuna", code: "681", iso: "WF" },
  { country: "Western Sahara", code: "212", iso: "EH" },
  { country: "Yemen", code: "967", iso: "YE" },
  { country: "Zambia", code: "260", iso: "ZM" },
  { country: "Zimbabwe", code: "263", iso: "ZW" },
  { country: "Dubai", code: "971", iso: "ZW" },
];
