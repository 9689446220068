import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import { getCardTitles } from "../../redux/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function SelectTitle({
  typeOfTitle,
  setTypeOfTitle,
  typeOfPrintForLatin,
  setTypeOfPrintForLatin,
  typeOfPlan,
  setTypeOfPlan,
  typeOfPrint,
  setTypeOfPrint,
  setStep,
  ...props
}) {
  const [allTitles, setAllTitles] = useState([]);
  const params = useParams();
  useEffect(() => {
    props.getCardTitles(undefined, (data) => {
      for (let i = 0; i < data.data.results.length; i++) {
        if (data.data.results[i].id == typeOfTitle.id) {
          setTypeOfTitle(data.data.results[i]);
        }
      }
      setAllTitles(data.data.results);
    });
    if (props.buyingProducts[params.id - 1].data.is_koob == true) {
      setTypeOfPrint(2);
    }
  }, {});

  const goBack = () => {
    if (props.justThisStep == true) {
      setStep(4);
      props.setJustThisStep(false);
    } else {
      setStep(1);
    }
  };

  const handleGoNext = () => {
    if (
      (props.buyingProducts[params.id - 1].data.has_latin == true &&
        typeOfPrintForLatin === "") ||
      typeOfPlan === "" ||
      typeOfPrint === ""
    ) {
      toast.error("Complete information");
    } else {
      if (props.justThisStep == true) {
        props.setJustThisStep(false);
        setStep(4);
      } else {
        setStep(3);
      }
    }
  };

  return (
    <div className={"selectTitleContainer"}>
      {props.buyingProducts[params.id - 1].data.is_koob != true ? (
        <>
          <p className={"titleName"}>Please select your print type</p>
          <div className={"typeOfPrint"}>
            <button
              style={
                typeOfPrint == 1
                  ? { backgroundColor: "black", color: "white" }
                  : null
              }
              onClick={() => setTypeOfPrint(1)}
            >
              Colorful
            </button>
            <button
              style={
                typeOfPrint == 2
                  ? { backgroundColor: "black", color: "white" }
                  : null
              }
              onClick={() => setTypeOfPrint(2)}
            >
              bulging
            </button>
          </div>
        </>
      ) : null}
      <p className={"titleName"}>
        Please select your {typeOfPrint == 1 ? "Colorful" : "bulging"} print
        type.
        {typeOfPrint == 2 ? (
          <>
            (every 100 is
            <span
              style={{
                fontFamily: "sans-serif",
                fontSize: "16px",
                fontWeight: "bold",
                marginLeft: "4px",
              }}
            >
              99 $
            </span>
            )
          </>
        ) : (
          ""
        )}
      </p>
      <div className={"typeOfPlan"}>
        {typeOfPrint == 1 ? (
          <>
            <button
              className={typeOfPlan == 0 ? "black selected" : "black"}
              onClick={() => setTypeOfPlan(0)}
            >
              Black
            </button>
            <button
              className={typeOfPlan == 1 ? "brown selected" : "brown"}
              onClick={() => setTypeOfPlan(1)}
            >
              Brown
            </button>
            <button
              className={typeOfPlan == 2 ? "red selected" : "red"}
              onClick={() => setTypeOfPlan(2)}
            >
              Red
            </button>
            <button
              className={typeOfPlan == 3 ? "yellow selected" : "yellow"}
              onClick={() => setTypeOfPlan(3)}
            >
              Yellow
            </button>
            <button
              className={typeOfPlan == 4 ? "gray selected" : "gray"}
              onClick={() => setTypeOfPlan(4)}
            >
              White for plexiglass cards
            </button>
          </>
        ) : (
          <>
            <button
              className={typeOfPlan == 5 ? "gold selected" : "gold"}
              onClick={() => setTypeOfPlan(5)}
            >
              Goldsmith
            </button>
            <button
              className={typeOfPlan == 6 ? "silver selected" : "silver"}
              onClick={() => setTypeOfPlan(6)}
            >
              Silver hammer
            </button>
          </>
        )}
      </div>
      {props.buyingProducts[params.id - 1].data.has_latin == true ? (
        <>
          <p className={"titleName"}>
            Please choose the Latin print for the name of the bride and groom on
            the card envelope.
          </p>
          <div className={"typeOfPrintLatin"}>
            <button
              className={typeOfPrintForLatin == 0 ? "selected" : ""}
              onClick={() => setTypeOfPrintForLatin(0)}
            >
              Black
            </button>
            <button
              className={typeOfPrintForLatin == 5 ? "selected" : ""}
              onClick={() => setTypeOfPrintForLatin(5)}
            >
              Gold
            </button>
            <button
              className={typeOfPrintForLatin == 6 ? "selected" : ""}
              onClick={() => setTypeOfPrintForLatin(6)}
            >
              Silver
            </button>
          </div>
        </>
      ) : null}
      {allTitles.length > 0 &&
      props.buyingProducts[params.id - 1].data.is_card == true ? (
        <>
          <p className={"titleName"}>
            Please select the title type if you wish.
          </p>
          <div className={"typeOfTitr"}>
            <Swiper
              cssMode={true}
              navigation={true}
              pagination={{ dynamicBullets: true }}
              mousewheel={true}
              keyboard={true}
              modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              className="titleType"
            >
              {allTitles.map((item) => (
                <SwiperSlide>
                  <div
                    className={"titleContainerBuyProcess"}
                    onClick={() => setTypeOfTitle(item)}
                  >
                    <img src={item.image} alt={"wedding-cart"} />
                    <button
                      className={item.id == typeOfTitle.id ? "selected" : ""}
                    >
                      Select
                    </button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      ) : null}
      <div className={"backOrNextContainer"}>
        <button onClick={goBack}>Back</button>
        <button onClick={handleGoNext}>Accept and continue</button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  buyingProducts: state.counter.buyingProducts,
});

export default connect(mapStateToProps, { getCardTitles })(SelectTitle);
